import React from 'react';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CollapsibleMenu from '../../menu/collapsible_menu';
import {scrollToTop} from '../../../utils/app_utils';
import {Link} from 'react-router-dom';
import useHeaderConstant from '../../../apiRequests/header_const_api';
import Loader from '../../UI/loader';
import useCustomerLoginConstant from '../../../apiRequests/customer_login_header_const_api';
import {useSingletonApiData} from '../../../store/GlobalContext/ContextStore';
import Button from '@mui/material/Button';
import {useHistory} from 'react-router-dom';
import {flexbox} from '@mui/system';
import {useSelectedMultilingualLanguage} from '../../../store/GlobalContext/ContextStore';
import {MarginRightView} from '../../../pages/customer_portal_lending/home/pmayClassUpdateStyle';
const ID_VAL = 'id value';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <div
      style={{padding: 0, margin: 0}}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HomeloanButton = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
  color: white;
  background: linear-gradient(301.97deg, #024182 1.86%, rgba(2, 65, 130, 0.64) 98.56%);
  width: '100%'
  margin-left: 8px;
  height: 60px;
  .applyhomeloan{
    margin-left: 25.6px;
  }
`;

const SideDrawer = ({isTopHeaderPinned, ...rest}, ref) => {
  const selectedContextLanguage = useSelectedMultilingualLanguage();
  const {commonButtonStrings} = useSingletonApiData();
  const [menuOpened, setMenuOpened] = React.useState(false);
  const [value, setValue] = React.useState(0);

  React.useImperativeHandle(ref, () => ({
    onLogoClick: () => setMenuOpened(false),
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getTabStyleProps(index) {
    return {
      textTransform: 'none',
      letterSpacing: 0.5,
      fontSize: 15,
      fontWeight: value === index ? 'bold' : 'normal',
    };
  }
  const {isLoading, error, data: headerData} = useHeaderConstant();
  console.log('datasssss', headerData);
  const {data: dataList} = useCustomerLoginConstant();
  if (isLoading) return <Loader loading={true} />;
  function tab1() {
    return headerData?.mainHeaderLinks.map(({text, link, id}) => (
      <div>
        <Link
          onClick={() => {
            scrollToTop();
            setMenuOpened(false);
          }}
          style={{textDecoration: 'none'}}
          to={link}>
          <div style={{margin: '1.5rem', color: 'black', letterSpacing: 0.5}}>
            {text}
          </div>
        </Link>
        <Divider />
      </div>
    ));
  }

  function tab2() {
    return [
      ...headerData?.navMenuItems,
      {
        subMenuText: dataList?.placeholder,
        containerStyle: {
          color: 'white',
          background:
            'linear-gradient(301.97deg, #024182 1.86%, rgba(2, 65, 130, 0.64) 98.56%)',
        },
        textStyle: {color: 'white', fontWeight: 600},
        isExternalLink: true,
        subMenuList: [
          {
            headingText: '',
            list: dataList?.login,
          },
        ],
      },
    ].map(({subMenuText, displayTwoCircles, subMenuList, ...item}, index) => (
      <CollapsibleMenu
        {...item}
        key={index}
        name={subMenuText}
        items={subMenuList}
        displayTwoCircles={displayTwoCircles}
        onLinkClicked={(id) => {
          localStorage.setItem(ID_VAL, id);
          setMenuOpened(false);
        }}
      />
    ));
  }
  const handleClick = () => {
    window.open(
      'https://online.lichousing.com/Online_app/index.php',
      '_blank', // <- This is what makes it open in a new window.
    );
  };
  const list = () => (
    <div style={{width: '100vw'}} role="presentation">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        variant="fullWidth"
        indicatorColor="primary"
        style={{background: '#F9F9F9'}}>
        <Tab
          label={commonButtonStrings?.PersonalTab}
          style={getTabStyleProps(0)}
          {...a11yProps(0)}
        />
        <Tab
          label={commonButtonStrings?.ProductsTab}
          style={getTabStyleProps(1)}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        {tab1()}
        <HomeloanButton>
          <Link
            className="applyhomeloan"
            onClick={handleClick}
            style={{
              color: 'white',
              fontWeight: 600,
              textDecoration: 'none',
              isExternalLink: true,
            }}>
            {commonButtonStrings.applyHomeLoan}
          </Link>
        </HomeloanButton>
      </TabPanel>
      <TabPanel
        style={{
          overflow: 'auto',
          overflowY: 'scroll',
        }}
        value={value}
        index={1}>
        <div>
          {tab2()}

          {/* <HomeloanButton>
            <Link className='applyhomeloan' to={"/home-loan-enquiry"}
              onClick={handleClick}
              style={{
                color: 'white',
                fontWeight: 600,
                textDecoration: 'none',
                isExternalLink: true,
              }}
            >
              {selectedContextLanguage == 'hin' ? "गृह ऋण लागू करें" : "Apply for Home Loan"}
            </Link>
          </HomeloanButton> */}
        </div>
      </TabPanel>
    </div>
  );

  return (
    <div>
      <div
        onClick={() => setMenuOpened((prev) => !prev)}
        style={{
          background:
            'linear-gradient(301.97deg, #024182 1.86%, rgba(2, 65, 130, 0.64) 98.56%)',
          borderRadius: '50%',
          padding: '6px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {menuOpened ? (
          <CloseIcon style={{color: 'white', fontSize: 20}} />
        ) : (
          <MenuIcon style={{color: 'white', fontSize: 20}} />
        )}
      </div>
      <Drawer
        PaperProps={{
          style: {
            position: 'absolute',
            top: !isTopHeaderPinned ? '4.5rem' : '5.8rem',
            elevation: 0,
          },
        }}
        BackdropProps={{invisible: true}}
        anchor={'left'}
        open={menuOpened}
        onClose={() => setMenuOpened(false)}
        onOpen={() => setMenuOpened(true)}>
        {list()}
      </Drawer>
    </div>
  );
};

export default React.forwardRef(SideDrawer);
